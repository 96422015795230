import ANTRAG_TYPES from './types';
import { getInitialState } from './index';
import Vue from 'vue'

function checkMissingId(payload) {
  if (!payload.id) {
    throw new Error('id is required to run this mutation')
  }
}

// When there are duplicate fonds there have to be additional identifier to find the correct fond when editing
function checkAdditionalIdentifier(position, fonds, additionalIdentifier) {
  var arePropertiesSame = true
  if (additionalIdentifier && additionalIdentifier.length) {
    additionalIdentifier.forEach(item => {
      if ((fonds[item] || position[item]) && fonds[item] !== position[item]) {
        arePropertiesSame = false
      }
    })
  }
  return arePropertiesSame
}

function checkMissingCategoryId(payload) {
  if (!payload.categoryId) {
    throw new Error('categoryId is required to run this mutation')
  }
}

function checkMissingFondsList(payload) {
  if (!payload.fonds || !Array.isArray(payload.fonds)) {
    throw new Error('a fond list is required here');
  }
}

function checkCountPositios(countPos, data, categoryId) {
  if (data['#CountPos_' + categoryId] != undefined) {
    Vue.set(data, '#CountPos_' + categoryId, ''+countPos);
    if (countPos && data['#UncheckHasPos_' + categoryId] != undefined) {
      data[data['#UncheckHasPos_' + categoryId]] = "0";
    }
  }
}
/**
 * This method iterates the incoming warnings from the backend and then sets the
 * property stepKey accordingly (because this property comes as a null value initially)
 * @param {*} payload 
 * @param {*} components 
 * @param {*} steps 
 * @returns 
 */
function prepareWarnings(payload, components, steps) {
  const warnings = payload.warnings || {};
  if (warnings && warnings.antragWarnings)
    warnings.antragWarnings.forEach(warning => {
      warning.stepKey = getWarningStepKey(warning, components, steps)
      warning.substepKey = getWarningSubstepKey(warning, components, steps)
    });
  if (warnings && warnings.positionWarnings)
    warnings.positionWarnings.forEach(warning => {
      warning.stepKey = getWarningStepKey(warning, components, steps)
      warning.substepKey = getWarningSubstepKey(warning, components, steps)
    });

  return warnings;
}

/**
 * This method checks what component has the same key as the provided warning id and then
 * return the found key
 * @param {*} warning 
 * @param {*} components 
 * @param {*} steps 
 * @returns 
 */
function getWarningStepKey(warning, components, steps) {
  if (warning && warning.id) {
    const warningId = warning.id.includes('§§') ? warning.id.split('§§')[0] : warning.id;
    for (const key of Object.keys(components)) {
      const componentList = components[key];
      for (const comp of componentList) {
        if (comp.id === warningId || comp.config?.validationMessageID === warningId 
            || (comp.type === 'MULTIPLE_SELECTION' && comp.config?.componentFields?.some(field => field?.id === warningId))) {

          // If there's a key that doesn't exist in steps it should be a substepKey
          if (!steps.some(step => step.stepKey === key)) {
            const correctStep = steps ? steps.find(step => step.substeps?.length && step.substeps?.find(substep => substep.substepKey === key)) : undefined
            if (correctStep) {
              return correctStep.stepKey
            }
          }
          return key; 
        }

        if (comp.config?.positionFields?.length && comp.config.positionFields.find(field => field.id === warningId)) {
          if (!steps.some(step => step.stepKey === key)) {
            const correctStep = steps ? steps.find(step => step.substeps?.length && step.substeps?.find(substep => substep.substepKey === key)) : undefined
            if (correctStep) {
              return correctStep.stepKey
            }
          }
          return key;
        }
        
      }
    }
  } else if (warning && warning.title) {
    const warnStep = steps? steps.find(step => step.title == warning.title) : undefined;
    if (warnStep) {
      return warnStep.stepKey;
    }
  }
  return null;
}

/**
 * This method checks what component has the same key as the provided warning id and then
 * return the found key
 * @param {*} warning 
 * @param {*} components 
 * @param {*} steps 
 * @returns 
 */
 function getWarningSubstepKey(warning, components, steps) {
  if (warning && warning.id) {
    const warningId = warning.id.includes('§§') ? warning.id.split('§§')[0] : warning.id;
    for (const key of Object.keys(components)) {
      const componentList = components[key];

      for (const comp of componentList) {
        if (comp.id === warningId || comp.config?.validationMessageID === warningId) {

          // If there's a key that doesn't exist in steps it should be a substepKey
          if (!steps.some(step => step.stepKey === key)) {
            return key
          }
          return null; 
        }
        if (comp.config?.positionFields?.length && comp.config.positionFields.find(field => field.id === warningId)) {
          const correctStep = steps.find(step => step.substeps?.length && step.substeps?.find(substep => substep.substepKey === key))
          if (correctStep && correctStep.substeps.find(substep => substep.label === warning.title && comp.label === warning.title)) {
            return key
          }
        }
        
      }
    }
  }
  return null;
}

export default {
  [ANTRAG_TYPES.MUTATIONS.GET_ANTRAG_SUCCESS](state, payload) {
    checkMissingId(payload)

    const components = payload.components || {}
    prepareWarnings(payload, components, payload.steps || {});

    Vue.set(state.antraege, payload.id, Object.assign({highestStepVisited: payload.data?.antragsdatenId ? payload.steps?.length : -1}, {currentStep: 0}, payload));
    Vue.set(state.antraegeData, payload.id, Object.assign({}, payload.data));
    Vue.set(state.positions, payload.id, Object.assign({}, payload.positions));
    Vue.set(state.positionData, payload.id, Object.assign({}, payload.positionData));

    Vue.set(state.antraege[payload.id], 'dataHasChanged', false);
    Vue.set(state.antraege[payload.id], 'hatPDF', payload.hatPDF);
  },

  [ANTRAG_TYPES.MUTATIONS.RELOAD_ANTRAG_SUCCESS](state, payload) {
    checkMissingId(payload)

    Vue.set(state.antraegeData, payload.id, {...state.antraegeData[payload.id], ...payload.data});
    Vue.set(state.antraegeData[payload.id], 'caretPosition', null);
    Object.keys(payload).forEach(key => {
      if (payload[key] == null)
        delete payload[key];
    });
    const components = state.antraege[payload.id].components || {}
    const warnings = prepareWarnings(payload, components, state.antraege[payload.id].steps || {});
    const antragWarnings = !state.antraege[payload.id].warnings.antragWarnings ? [] : state.antraege[payload.id].warnings.antragWarnings.filter(warning => {
      return payload.ignore === undefined || !warning.id || warning.id != payload.ignore;
    })
    const warns = {...state.antraege[payload.id].warnings, antragWarnings: antragWarnings};
    const antrag = {
      ...state.antraege[payload.id],
      comboboxSelections: {...state.antraege[payload.id].comboboxSelections, ...payload.comboboxSelections},
      multiSelectionsTabledata: {...state.antraege[payload.id].multiSelectionsTabledata, ...payload.multiSelectionsTabledata},
      components: {...state.antraege[payload.id].components, ...payload.components},
      bankverbindungData: {...state.antraege[payload.id].bankverbindungData, ...payload.bankverbindungData},
      warnings: {...warns, ...warnings},
    };
    // update only aktionen-step (wichtig für Vermittlerwechsel)
    if (payload.steps?.length === 1 && payload.steps[0].stepKey === 'aktionen' && antrag.steps?.length > 1) {
      antrag.steps[antrag.steps.length - 1] = payload.steps[0];
    } else if (payload.steps.length) {
      // update all steps
      antrag.steps = payload.steps;
    }
    Vue.set(state.antraege, payload.id, antrag);
  },

  [ANTRAG_TYPES.MUTATIONS.UPDATE_HIGHEST_STEP](state, {id, stepKey}) {
    checkMissingId({id});
    if (state.antraege[id]) {
      const stepIndex = state.antraege[id].steps.findIndex(step => step.stepKey == stepKey);
      Vue.set(state.antraege[id], 'highestStepVisited', Math.max(stepIndex, state.antraege[id].highestStepVisited));
    }
  },

  [ANTRAG_TYPES.MUTATIONS.UPDATE_CURRENT_STEP](state, {id, stepKey}) {
    checkMissingId({id});
    if (state.antraege[id]) {
      const stepIndex = state.antraege[id].steps.findIndex(step => step.stepKey == stepKey);
      Vue.set(state.antraege[id], 'currentStep', stepIndex);
    }
  },

  [ANTRAG_TYPES.MUTATIONS.REMOVE_ANTRAG_WARNING](state, payload) {
    checkMissingId(payload)

    if (state.antraege[payload.id]?.warnings) {
    Vue.set(state.antraege[payload.id].warnings, 'antragWarnings', []);
    Vue.set(state.antraege[payload.id].warnings, 'positionWarnings', []);
    }
  },

  [ANTRAG_TYPES.MUTATIONS.UPDATE_ANTRAG_DATA](state, payload) {
    if (!state.antraegeData[payload.id]?.READ_ONLY) {
      checkMissingId(payload)

      Vue.set(state.antraegeData, payload.id, Object.assign({}, state.antraegeData[payload.id], payload.data));
      Vue.set(state.antraege[payload.id], 'dataHasChanged', true);
    }
  },

  [ANTRAG_TYPES.MUTATIONS.UPDATE_ANTRAG](state, payload) {
    Vue.set(state.antraege, payload.id, Object.assign({}, state.antraege[payload.id], payload.data));
  },

  [ANTRAG_TYPES.MUTATIONS.UPDATE_POSITIONS](state, payload) {
    checkMissingId(payload)
    checkMissingCategoryId(payload)
    checkMissingFondsList(payload)

    if (!state.positions[payload.id]) {
      Vue.set(state.positions, payload.id, {});
    }

    if (!Array.isArray(state.positions[payload.id][payload.categoryId])) {
      Vue.set(state.positions[payload.id], payload.categoryId, []);
    }

    for (const fond of payload.fonds) {
      const index = state.positions[payload.id][payload.categoryId].findIndex(pos => pos.isin === fond.isin && checkAdditionalIdentifier(pos, fond, payload.additionalIdentifier));
      
      if (index > -1) {
        if(payload.canHaveDuplicateFonds){
          state.positions[payload.id][payload.categoryId].push({...fond})
        }else{
          const changedPosition = Object.assign({},
            state.positions[payload.id][payload.categoryId][index],
            fond)
    
          Vue.set(state.positions[payload.id][payload.categoryId], index, changedPosition);
        }

      } else {
        state.positions[payload.id][payload.categoryId].push({...fond})
      }
    }
    checkCountPositios(state.positions[payload.id][payload.categoryId].length, state.antraegeData[payload.id], payload.categoryId);
    Vue.set(state.antraege[payload.id], 'dataHasChanged', true);
  },

  [ANTRAG_TYPES.MUTATIONS.DELETE_POSITIONS](state, payload) {
    checkMissingId(payload)
    checkMissingCategoryId(payload)

    if (!state.positions[payload.id]) {
      Vue.set(state.positions, payload.id, {});
    }

    if (!Array.isArray(state.positions[payload.id][payload.categoryId])) {
      Vue.set(state.positions[payload.id], payload.categoryId, []);
    }

    let index;
    if (payload.posGrpId) {
      index = state.positions[payload.id][payload.categoryId].findIndex(pos => pos.posGrpId === payload.posGrpId);
    } else if (payload.isin) {
      index = state.positions[payload.id][payload.categoryId].findIndex(pos => pos.isin === payload.isin);
    } 
    if (index > -1) {
      state.positions[payload.id][payload.categoryId].splice(index, 1)
    }
    checkCountPositios(state.positions[payload.id][payload.categoryId].length, state.antraegeData[payload.id], payload.categoryId);
    
    // delete categoryId attribute from the object when the categoryId is an empty array
    if(!state.positions[payload.id][payload.categoryId] || !state.positions[payload.id][payload.categoryId].length) {
      delete state.positions[payload.id][payload.categoryId];
    }

  },

  [ANTRAG_TYPES.MUTATIONS.DELETE_ALL_POSITIONS_PRO_CATEGORY](state, payload) {
    checkMissingId(payload)
    checkMissingCategoryId(payload)

    if (!state.positions[payload.id] || !Array.isArray(state.positions[payload.id][payload.categoryId])) {
      return;
    }

    checkCountPositios(0, state.antraegeData[payload.id], payload.categoryId);
    delete state.positions[payload.id][payload.categoryId];
  },

  [ANTRAG_TYPES.MUTATIONS.UPDATE_POSITION_DATA](state, payload) {
    checkMissingId(payload)
    checkMissingCategoryId(payload)

    if (!state.positionData[payload.id]) {
      Vue.set(state.positionData, payload.id, {});
    }

    if (!state.positionData[payload.id][payload.categoryId]) {
      Vue.set(state.positionData[payload.id], payload.categoryId, {});
    }

    Vue.set(state.positionData[payload.id], payload.categoryId, Object.assign({}, state.positionData[payload.id][payload.categoryId], payload.positionData));
    Vue.set(state.antraege[payload.id], 'dataHasChanged', true);
  },

  [ANTRAG_TYPES.MUTATIONS.SAVE_ANTRAG_SUCCESS](state, payload) {
    checkMissingId(payload)
    if (!payload.data) {
      return
    }
    const components = state.antraege[payload.id].components || {}
    const warnings = prepareWarnings(payload, components, state.antraege[payload.id].steps || {});
    Vue.set(state.antraege[payload.id], 'warnings', warnings);
    Vue.set(state.antraege[payload.id], 'data', Object.assign({}, state.antraege[payload.id].data, payload.data));
    Vue.set(state.antraegeData, payload.id, Object.assign({}, state.antraegeData[payload.id], payload.data));
    Vue.set(state.antraege[payload.id], 'dataHasChanged', false);
    Vue.set(state.positions, [payload.id], payload.positions);
    Vue.set(state.antraege[payload.id], 'hatPDF', payload.hatPDF);
  },

  [ANTRAG_TYPES.MUTATIONS.RESET_STATE](state) {
    Object.assign(state, getInitialState())
  },
 
  [ANTRAG_TYPES.MUTATIONS.RESET_ANTRAG_ACTION_LOADING_ACTIVE](state) {
    Object.assign(state.antragActionLoading, getInitialState().antragActionLoading)
  },

  [ANTRAG_TYPES.MUTATIONS.SET_REQUESTED_EBASE_DEPOTNUMMER](state, payload) {
    checkMissingId(payload)
    if (!payload.data) {
      return
    }

    Vue.set(state.requestedEbaseDepotnummer, payload.id, payload.data);
  },

  [ANTRAG_TYPES.MUTATIONS.SET_REQUESTED_EBASE_AAB_ANLAGESTRATEGIEN](state, payload) {
    checkMissingId(payload)
    if (!payload.data) {
      return
    }

    Vue.set(state.requestedEbaseAABAnlagestrategien, payload.id, payload.data);
  },

  [ANTRAG_TYPES.MUTATIONS.SET_ANTRAG_RELOAD_PAYLOAD](state, payload) {
    checkMissingId(payload);

    Vue.set(state.antraegeReloadPayload, payload.id, payload);
  },

  [ANTRAG_TYPES.MUTATIONS.END_SAVE_ANTRAG_STATE](state) {
    Vue.set(state, 'saveAntragState', false)
  },

  [ANTRAG_TYPES.MUTATIONS.START_SAVE_ANTRAG_STATE](state) {
    Vue.set(state, 'saveAntragState', true)
  },

  [ANTRAG_TYPES.MUTATIONS.RETRIEVE_ANTRAG_LIST_SUCCESS](state, payload) {
    state.antragList = payload;
  }, 

  [ANTRAG_TYPES.MUTATIONS.SET_ANTRAG_LIST_FORMULARE](state, payload) {
    state.antragListFormulare = { ...payload || {} };
  }, 

  [ANTRAG_TYPES.MUTATIONS.PORTFOLIO_STRUKTUR_SUCCESS](state, payload) {
    if (payload.antragId && payload.componentId && payload.data) {
      const data = { [payload.componentId]: payload.data.positions, errorMessage: payload.data.errorMessage }
      Vue.set(state.antraegeData, payload.antragId, Object.assign({}, state.antraegeData[payload.antragId], data ));
    }
  }, 
  [ANTRAG_TYPES.MUTATIONS.SET_ATTACHMENTS](state, payload) {
     Vue.set(state, 'attachmentsTemp', state.attachmentsTemp.concat(payload));
  },

  [ANTRAG_TYPES.MUTATIONS.RESET_ATTACHMENTS](state) {
    state.attachmentsTemp =  [];
  },

  [ANTRAG_TYPES.MUTATIONS.RETRIEVE_GESELLSCHAFTS_FORMULARE_SUCCESS](state, payload) {
    state.gesellschaftsFormulare = payload;
  },

  [ANTRAG_TYPES.MUTATIONS.RETRIEVE_GESELLSCHAFTS_FORMULARE_LINKS_SUCCESS](state, payload) {
    state.gesellschaftsFormulareLinks = payload;
  },

  [ANTRAG_TYPES.MUTATIONS.RETRIEVE_GESELLSCHAFTS_MAINSTRUCTURE_SUCCESS](state, payload) {
    state.gesellschaftsMainStructure = payload;
  },  

  [ANTRAG_TYPES.MUTATIONS.GET_EFONDS_PERSONS_SUCCESS](state, payload) {
    if (payload?.data?.persons && payload?.id && state.antraege && state.antraege[payload.id]) {
      checkMissingId(payload);
      state.antraege[payload.id] = Object.assign(state.antraege[payload.id] || {}, { comboboxSelections: {...state.antraege[payload.id].comboboxSelections, persons: payload.data.persons }})
    }
  }, 
  [ANTRAG_TYPES.MUTATIONS.GET_FREMDWAEHRUNG_VALUES_SUCESS](state, payload) {
    state.positions = payload;
  },
 
  [ANTRAG_TYPES.MUTATIONS.UPDATE_POSITIONS_FREMDWAEHRUNG](state, payload) {
    checkMissingFondsList(payload)

    for (const fond of payload.fonds) {
      const index = state.positions.payload.findIndex(pos => pos.isin === fond.isin);

      if (index > -1) {
        const changedPosition = Object.assign({},
          state.positions.payload[index],
          fond)
        Vue.set(state.positions.payload, index, changedPosition);
      } else {
        state.positions.payload.push({...fond})
      }
    } 
    Vue.set(state.antraege['EBASE-ebasefremdwaehrung'], 'dataHasChanged', true);
  },

  [ANTRAG_TYPES.MUTATIONS.CAPITALBANK_DEPOTS_VORHANDEN_SUCCESS](state, payload) {
    state.capitalbankDepotsVorhanden = payload;
  }, 

  [ANTRAG_TYPES.MUTATIONS.SET_TODO_VIDEO_INDENT](state, payload) {
    state.videoindent = payload;
  }, 

  [ANTRAG_TYPES.MUTATIONS.SET_ANTRAG_ACTION_LOADING](state, { action, value }) {
    Vue.set(state.antragActionLoading, action, value);
  },

  [ANTRAG_TYPES.MUTATIONS.START_ANTRAG_ACTION_LOADING](state, action) {
    Vue.set(state.antragActionLoading, action, true);
  },

  [ANTRAG_TYPES.MUTATIONS.STOP_ANTRAG_ACTION_LOADING](state, action) {
    Vue.set(state.antragActionLoading, action, false);
  },

  [ANTRAG_TYPES.MUTATIONS.UPDATE_IS_VORGELEGT](state, payload) {
    if (!state.antraegeData[payload?.id]?.READ_ONLY) {
      checkMissingId(payload)

      Vue.set(state.antraegeData, payload.id, Object.assign({}, state.antraegeData[payload.id], { IS_VORGELEGT: payload?.IS_VORGELEGT }));
      const warnings = state.antraege[payload.id]?.warnings || [];
      Vue.set(state.antraege[payload.id], 'warnings', Object.assign([], warnings, payload.warnings));

    }
  },

}